<template>
  <section class="dtc-main-section11 doc6 mt-1" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件4
    </header>
    <header
      class="mb-2 comment-one-head"
      style="font-size:20px;display:grid;place-items:center;font-family:DFKai-sb;"
    >
      {{ schoolName }}學校{{ year }}學年度{{
        term == 1 ? "上" : "下"
      }}學期偏鄉護理菁英公費生補助培育學校經費核銷總表
      <small
        style="font-size : 9px;-webkit-transform : scale(0.95);"
        class="comment-one"
        >註1</small
      >
    </header>
    <h6 style="font-size:20px;">申請學校:</h6>

    <header class="grid-6-dtc">
      <div>{{ names[0] }}</div>
      <div>{{ names[1] }}</div>
      <div class="comment-two-head">
        {{ names[2] }}
        <small
          style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
          class="comment-two"
          >註2</small
        >
      </div>
      <div class="grid-3-dtc">
        <div>品項名稱及數量</div>
        <div>單價</div>
        <div>總價(數量x單價)</div>
      </div>
      <div class="comment-three-head">
        <small
          style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
          class="comment-three"
          >註3</small
        >
        {{ names[4] }}
      </div>
      <div>{{ names[5] }}</div>
    </header>
    <main class="grid-8-dtc">
      <div></div>
      <div>{{ map.NumberOfPeople }}</div>
      <div>{{ deviceFeeApply }}</div>
      <div class="row-4-dtc">
        <div v-for="(item, i) in deviceFee" :key="i">
          {{ item.ItemName }} / {{ item.Quantity }}
        </div>
        <!-- <div></div> -->
      </div>
      <div class="row-4-dtc">
        <div v-for="(item, i) in deviceFee" :key="i" class="text-right-mode">
          {{ $formatPrice(item.Price) }}
        </div>
        <!-- <div></div> -->
      </div>
      <div class="row-4-dtc">
        <div v-for="(item, i) in deviceFee" :key="i" class="text-right-mode">
          {{ $formatPrice(item.SubTotal) }}
        </div>
        <!-- <div></div> -->
      </div>

      <div class="text-right-mode">
        {{ $formatPrice(subTotalFeeApply) }}
      </div>

      <div style="border:0px" class="text-right-mode">
        {{ $formatPrice(totalFeeApply) }}
      </div>

      <div
        v-if="term == 1"
        style="grid-column: 1/-1;min-height:30px;border-top: 1px solid black;line-height: 30px;text-align:left;padding-left:13.5px"
      >
        備註 : 設備費總金額 {{ $formatPrice(deviceFeeReal) }} ，由衛福部補助
        {{ deviceFeeApply }}
        <span v-if="term == 1"
          >， 其餘 {{ $formatPrice(remainderFee) }} 由學校自籌款支付</span
        >
      </div>
    </main>

    <footer class="dtc-note">
      <div>備註</div>
      <div>
        註1:
        補助培育學校經費包括業務費、行政管理費及設備費，請依「培育學校經費支用範圍及編列基準」核實辦理。
      </div>
      <div>
        註2:
        設備費：每學年每位公費生補助新臺幣1萬5,000元整，核銷時以上學期為限，設備定義為單價1萬元以上且使用年限在2年以上者，並須檢附財產增加單送衛生福利部備查。設備採購金額或品項倘有變更，應於每年11月底前函報本部進行變更，並以一次為原則。
      </div>
      <div>註3: 申請公費生業務費，包括學生輔導費、事務性支出及差旅費等。</div>
      <div style="transform:translateX(19px)">
        (1)有關學生輔導費申請，請依附表4-4個別輔導紀錄表、附表4-3總輔導紀錄表、及隨同附表4-2印領清冊明細表辦理核銷。
      </div>
      <div style="transform:translateX(19px)">
        (2)事務性支出及差旅費等依實際核實支付。
      </div>
      <div style="transform:translateX(-9px)">其他</div>
      <div>
        1.申請撥款時間：請於每年10月底前(上學期)及次年4月底(下學期)前將領款收據送衛生福利部辦理撥款作業。
      </div>
      <div class="note">
        <div>2.核銷時間：</div>
        <div>
          (1)設備費請於每年11月底(上學期：8月至次年1月)前將原始憑證及財產增加單送衛生福利部核銷。
        </div>
        <div></div>
        <div>
          (2)輔導費請於每年11月底(上學期：8月至次年1月)及5月底(下學期：2月至7月)前，將輔導紀錄表及總表送衛生福利部辦理核銷作業。
        </div>
      </div>
    </footer>
    <footer class="mt-1 ml-5 mb-4 grid-footer-4">
      <div>註冊組</div>
      <div>出納組</div>
      <div>會計(財務)</div>
      <div>校長</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
const names = [
  "項目",
  "學生人數",
  "設備費(15000元/每學年每學生)(限上學期報支)",
  "",
  "業務費及行政管理費/申請金額 (10000元/每學期每學生)",
  "培育學校經費總計",
];

export default {
  name: "totalSpend11",
  data() {
    return {
      names,
      schoolName: "",
      year: "",
      term: "",
      map: [],
      totalFee: "",
      deviceFee: [],
      serviceFee: [],
      administrateFee: [],
      subTotalFee: "",
      deviceFeeApply: "",
      deviceFeeApplyNum: "",
      deviceFeeReal: "",
      remainderFee: "",
      subTotalFeeApply: "",
      totalFeeApply: "",
    };
  },
  methods: {
    async getData() {
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "110",
        term = 1,
        category: Category = "N",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = term; // == 1 ? '上' : '下';
      this.year = Byear;
      let schoolArr = await window.axios.get("School/GetAll");
      this.schoolName = schoolArr.find(
        (s) => s.SchoolCode == SchoolCode
      ).SchoolName;
      try {
        this.map = await axios.get(
          `NurseDevice/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
        );

        this.deviceFeeReal = this.map.DeviceCost;
        this.deviceFee = this.map.nursedevices;

        if (term == 1) {
          this.deviceFeeApply = `${this.$formatPrice(
            // this.map.TotalPeople * 15000
            this.map.DeviceTotal
          )}`;
          // this.deviceFeeApplyNum = this.map.TotalPeople * 15000;
          this.deviceFeeApplyNum = this.map.DeviceTotal;
        } else {
          this.deviceFeeApply = `已於${this.year}學年度第1學期撥付完竣`;
          this.deviceFeeApplyNum = 0;
        }
        this.remainderFee =
          this.deviceFeeReal - this.deviceFeeApplyNum > 0
            ? this.deviceFeeReal - this.deviceFeeApplyNum
            : 0;
        // this.remainderFee = this.map.DeviceSelf;

        // this.subTotalFeeApply = this.map.TotalPeople * 10000;
        this.subTotalFeeApply = this.map.SalesTotal;
        this.totalFeeApply = this.subTotalFeeApply + this.deviceFeeApplyNum;
      } catch (e) {
        alert(e);
      }
    },
  },

  async mounted() {
    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
};
</script>

<style lang="scss">
.dtc-main-section11 {
  margin: 0 auto;
  width: 1140px;
  .comment-one-head {
    position: relative;
    .comment-one {
      position: absolute;
      top: 28%;
      right: 15.5%;
    }
  }
}
.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 268px;
  font-size: 14px !important;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}
.note {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.grid-6-dtc {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 60px 120px 200px 440px 200px 120px;
  border: 1px solid black;
  > div {
    height: 80px;
    font-size: 14px;
    text-align: center;
    border-right: 1px solid black;
    padding-top: 10px;
    //line-height: 100px;
  }
  > div:last-child {
    border-right: none;
  }
  .comment-two-head {
    position: relative;
    .comment-two {
      position: absolute;
      right: 14.5%;
      top: 43%;
    }
  }
  .comment-three-head {
    position: relative;
    .comment-three {
      position: absolute;
      right: 1%;
      top: 45%;
    }
  }
}
.grid-8-dtc {
  display: grid;
  grid-template-columns: 60px 120px 200px 199px 100px 141px 200px 120px;
  border: 1px solid black;
  border-top: none;
  > div {
    min-height: 30px;
    font-size: 16px;
    text-align: center;
    border-right: 1px solid black;
  }
  > div:last-child {
    border-right: none;
  }
}
.row-4-dtc {
  display: flex;
  flex-direction: column;
  > div {
    height: 30px;
    border-bottom: 1px solid black;
    font-size: 16px;
  }
  > div:last-child {
    border-bottom: none;
  }
}
.grid-3-dtc {
  display: grid;
  grid-template-columns: 1fr 100px 140px;
  align-content: end;
  position: relative;
  &::before {
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    content: "設備費項目";
  }
  > div {
    height: 40px;
    text-align: center;
    border-right: 1px solid black;
    border-top: 1px solid black;
    line-height: 40px;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-footer-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 10px;
  font-size: 24px;
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-main-section {
    width: 1140px;
  }
}
</style>
